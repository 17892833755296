import React from "react";

import SEO from "../components/SEO";
import HeaderSection from "../components/partials/remotelearning/HeaderSection";
import TeacherResourcesSectionLP from "../components/partials/remotelearning/TeacherResourcesSectionLP";
import SchoolLeadersResourcesSection from "../components/partials/remotelearning/SchoolLeadersResourcesSection";
import TwitterStream from "../components/partials/remotelearning/TwitterStream";

const RemoteLearning = () => {
  return (
    <>
      <SEO
        title="Remote Learning"
        description="Keep teachers, students, and families connected and learning."
        image="https://static.classdojo.com/img/remote_learning/share_image.png"
        twitter={{
          card: "summary_large_image",
          site: "@classdojo",
          creator: "@classdojo",
          title: "Remote Learning",
          description: "Keep teachers, students, and families connected and learning.",
          image: "https://static.classdojo.com/img/remote_learning/share_image.png",
        }}
      />
      <HeaderSection />
      <TeacherResourcesSectionLP />
      <SchoolLeadersResourcesSection />
      <TwitterStream />
    </>
  );
};
export default RemoteLearning;
